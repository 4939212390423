// REACT AND GATSBY IMPORTS
import React, { useState } from 'react';
// STYLING
// CONTAINERS
// import BannerSection from '../../containers/SaasClassic/Banner'
import Box from 'reusecore/src/elements/Box'
// COMPONENTS (landing-gatsby)
import UsersLayout from 'gatsby-landing/src/components/UsersLayout';
// COMMON COMPONENTS
// import Loader from 'reusecore/src/elements/Loader'
import SignIn from 'gatsby-landing/src/containers/SaasClassic/SignIn/signin'
import SignUp from 'gatsby-landing/src/containers/SaasClassic/SignIn/signup'

// User interface for displaying login page
const LoginPage = () => {
  const [doSignup, updateDoSignup] = useState(false)
  const row = {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  }
  // console.log(doSignup)
  return (
    <UsersLayout seo={{ title: 'Log In to Your Podstacks Account' }} isLoginPage={true}>
      <Box {...row}>
        {doSignup ?
        <><SignUp heading='Sign Up' onSignInClick={()=> updateDoSignup(false)} /></>:
        <><SignIn heading='Log In' onSignUpClick={()=> updateDoSignup(true)} /></>
        }
      </Box>
      {/* <Loader loaderColor='red' height='200px' width='200px'/> */}
    </UsersLayout>
  );
};

export default LoginPage;
